module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"The Benjamin DeMann Blog","short_name":"DeBlog","start_url":"/","background_color":"#171717","theme_color":"#171717","display":"standalone","icon":"src/images/book.png","icons":[{"src":"src/images/book-maskable.png","sizes":"2880x2880","type":"image/png","purpose":"maskable"}],"crossOrigin":"use-credentials","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","include_favicon":true,"cacheDigest":"4b239e4798832c478fb3ce337f039406"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
